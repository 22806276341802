// Not sure why this doesn't auto-import
// import { type ContentBlock } from '@/schemas/contentBlocks'

export async function fetchPage(id: id) {
  const {
    attributes: page,
    pending,
    error,
    refresh,
    relationships,
  } = await useApiFetch<Page, { contentBlocks: ContentBlock[] }>(
    `/api/v1/cms/pages/${id}?include=content_blocks`,
  )
  const contentBlocks = computed(() => relationships.value?.contentBlocks)
  return { page, contentBlocks, pending, error, refresh }
}

export async function fetchPageBySlug(slug: string) {
  const {
    attributes: page,
    pending,
    error,
    refresh,
    relationships,
  } = await useApiFetch<Page, { contentBlocks: ContentBlock[] }>(
    `/api/v1/cms/pages/slug/${slug}?include=content_blocks`,
    {},
    false, // Don't throw an error if the page doesn't exist
  )
  const contentBlocks = computed(() => relationships.value?.contentBlocks)
  return { page, contentBlocks, pending, error, refresh }
}

export async function fetchPages(
  query?: SearchParameters | Ref<SearchParameters>,
) {
  const {
    attributes: pages,
    data,
    pending,
    error,
    refresh,
  } = await useApiFetch<Page[]>('/api/v1/cms/pages', { query }, false)
  return { data, pages, pending, error, refresh }
}

export interface PagePayload
  extends Omit<Page, 'id' | 'createdAt' | 'updatedAt'> {}

export async function createPage(attributes: PagePayload) {
  const { data: page } = await $api<JSONAPIResponse<Page>>(
    '/api/v1/cms/pages',
    {
      method: 'POST',
      body: {
        data: {
          type: 'page',
          attributes: attributes,
        },
      },
    },
  )

  return page
}

export async function updatePage(id: id, attributes: PagePayload) {
  const { data: page } = await $api<JSONAPIResponse<Page>>(
    `/api/v1/cms/pages/${id}`,
    {
      method: 'PATCH',
      body: {
        data: {
          type: 'page',
          attributes: attributes,
        },
      },
    },
  )

  return page
}

export async function deletePage(id: id) {
  await $api<JSONAPIResponse<Page>>(`/api/v1/cms/pages/${id}`, {
    method: 'DELETE',
  })
}
